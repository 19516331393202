import { render, staticRenderFns } from "./PromotionStickers.vue?vue&type=template&id=60dcb776&scoped=true&"
import script from "./PromotionStickers.vue?vue&type=script&lang=js&"
export * from "./PromotionStickers.vue?vue&type=script&lang=js&"
import style0 from "./PromotionStickers.vue?vue&type=style&index=0&id=60dcb776&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60dcb776",
  null
  
)

export default component.exports