<template>
  <v-container class="mb-2" v-if="promoted_products!=null">
    <v-overlay :value="isLoading">
    <v-progress-circular
      v-if="isLoading"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </v-overlay>
          <div class="d-flex align-center justify-center">
            <v-card v-for="(item, product_index) in promoted_products" :key="product_index" class="ma-1">
              <v-card-text class="font-weight-bold">
                <v-row v-if="logo_on_top">
                  <v-col>
                    <v-img :src="lotteries_images[item.product.type_name]" max-width="100"></v-img>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="rotated-text mr-2" cols="1" v-if="display_promotion_label">
                    <div class="text_vertical primary white--text pa-1"> {{ $t("message.productsPage.promotion") }}</div>
                  </v-col>
                  <v-col>
                <v-row align="center">
                  <v-col class="text-h2" style="position: relative;" cols="4" v-if="!logo_on_top">
                  <v-img :src="lotteries_images[item.product.type_name]" max-width="100"></v-img>
                  </v-col>
                  <v-col class="font-weight-black" :cols="logo_on_top?12:4">
                    <div>{{ item.product.quickPicks }} {{$t("message.productsPage.quickpicks")}}</div>
                    <div>{{ item.product.multiplay }} {{$t("message.productsPage.draws")}}</div>
                    <div>{{ $t("message.productsPage.multiplier."+ item.product.type_name + ".name")}}</div>
                  </v-col>
                  <v-col class="text-h2" style="position: relative;" cols="4" v-if="display_price">
                    <v-img src="/static/images/explosion_yellow.png" max-width="100">

                    </v-img>
                    <div class="overlay">
                    <span class="text">{{item.coupon.price}}${{ item.coupon.currency }}</span>
                  </div>
                  </v-col>
              </v-row>
              <v-row>
                <v-col class="text-center">
                  <v-btn class="primary" @click="addOffer(item)"> {{ $t("message.productsPage.iWantOffer") }}</v-btn>
                </v-col>
              </v-row>
            </v-col>
            </v-row>
              </v-card-text>
            </v-card>

          </div>
          <emb-delete-confirmation-2
            ref="promoAddedToCartDialog"
            :messageTitle="$t('message.productsPage.addedToCartDialog')"
            messageDescription=""
            @onConfirm="onCheckoutBtn"
            @onCancel="onCancelBtn"
            :btn1="$t('message.productsPage.continueShopping')"
            :btn2="$t('message.productsPage.goToCheckout')"
          ></emb-delete-confirmation-2>
        </v-container>
  </template>
  <style scoped>
  .overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  border-radius: 5px;
}
  .text {
  color: black;
  font-size: 32px; /* Adjust the font size as needed */
  font-weight: bold;
  text-shadow:
    -1px -1px 0 white,  
    1px -1px 0 white,
    -1px 1px 0 white,
    1px 1px 0 white;
}

.rotated-text {
  display: flex;
  justify-content: center;
}

.text_vertical {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  font-size: 18px; /* Adjust as needed */
}
  </style>
  <script>

import { getFunctions, httpsCallable } from "firebase/functions";

  export default {
    data() {
      return {
        promoted_products: [],
        selectedItem: null,
        isLoading: false,
        lotteries_images: {
          "megamillions": "/static/images/megamillions_mini.png",
          "powerball": "/static/images/powerball_mini.png",
        }
      };
    },
    props: ["type_name", "display_price", "display_promotion_label", "logo_on_top", "continue_callback", "onClose"],
    mounted() {
        this.$store.dispatch("get_promoted_products", {type_name:this.type_name}).then((promoted_products) => {
            this.promoted_products = promoted_products;
        });
    },
    methods: {
      addOffer(item) {
        this.isLoading = true;
        this.selectedItem = item;
        item.coupon.name = item.coupon.name.trim();
        if (item.coupon.name != "") {
          const validateCouponFunc = httpsCallable(
            getFunctions(),
            "validateCoupon"
          );
          validateCouponFunc({ coupon: item.coupon.name })
            .then((result) => {
              if (result.data.code == "OK") {
                // this.userCart.coupon = result.data.coupon;
                this.$store.commit("setCouponInfo", result.data.coupon.info);
                this.$store.dispatch("add_to_cart", {
                    ticket: item.product,
                    type: "quickpicks",
                  }).then(() => {
                    this.isLoading = false;
                  });

              } else {
                console.log("Invalid coupon");
                this.isLoading = false;
              }
            })
            .catch((error) => {
              console.error("Error: ", error);
              this.isLoading = false;
            });
        }
        this.$refs.promoAddedToCartDialog.openDialog();
      },
      onCheckoutBtn() {
        this.selectedItem = null;
        this.$refs.promoAddedToCartDialog.close();
        this.$router.push("/checkout/payment");
      },
      onCancelBtn() {
        this.$emit("onClose");
      }
    },
  };
  </script>
  